import React from 'react';
import PropTypes from 'prop-types';

// Style Imports
import './Table.scss';

const Table = ({
    className,
    variant,
    children,
}) => {
    //Class definitions
    const baseClass = 'table';
    const variantClass = variant ? `table--${variant}` : '';
    const customClass = className;
    const classes = [baseClass, variantClass, customClass].filter(Boolean).join(' ');

    return (
        <div className={classes}>
          <table>
            {children}
          </table>
        </div>
    );
}

Table.propTypes = {
    className: PropTypes.string,
    variant: PropTypes.string,
    children: PropTypes.node,
}

export default Table;