import React from 'react';
import ReactHtmlParser, { processNodes } from "react-html-parser";
import PropTypes from 'prop-types';

// Component Imports
import Table from '../Table/Table';

// Style Imports
import './ContentRenderer.scss';

const ContentRenderer = ({
  content,
}) => {
  // Transform content
  const transform = (node, index) => {
    
    // <img> tag transformation
    if (node.type === "tag" && node.name === "img") {
      const source = node.attribs.src;
      const alt = node.attribs.alt;
      
      return (
        <div className='image__wrapper' key={index}>
          <img className='image' src={source} alt={alt} />
        </div>
      )
    }

    if (node.type === "tag" && node.name === "table") {
      const className = node.attribs.class;

      return (
        <Table className={className} key={index}>
          {processNodes(node.children, transform)}
        </Table>
      );
    }
  }
  
  // Data options
  const dataOptions = {
    decodeEntities: true,
    transform,
  };

  return (
    <div className="content-renderer">
      {ReactHtmlParser(content.answer, dataOptions)}
    </div>
  );
}

ContentRenderer.propTypes = {
  content: PropTypes.object,
};

export default ContentRenderer;