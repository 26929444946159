import React from 'react';

// Layout Imports
import Default from '../layouts/Default/Default';
import SEO from '../layouts/SEO/SEO';

// Component Imports
import PageHeader from '../components/shared/PageHeader/PageHeader';
import Section from '../components/shared/Section/Section';
import Accordion from '../components/shared/Accordion/Accordion';
import ContentRenderer from '../components/shared/ContentRenderer/ContentRenderer';

// Content Imports
import Questions from '../content/faqs.json';

const Faqs = (props) => {
  return (
    <Default
      location={props.location}
      title='FAQs'
    >
      <SEO
        title='FAQs'
        keywords={[`blog`, `gatsby`, `javascript`, `react`]}
      />

      <PageHeader
        heading='FAQs'
        description="Got questions? We have answers... maybe"
      />

      <Section accent>
        {Questions.faqs.map((content, i) => (
          <Accordion buttonText={content.question} id={`faq-${i+1}`} key={i+1}>
            {<ContentRenderer content={content} />}
          </Accordion>
        ))}
      </Section>
    </Default>
  );
};

export default Faqs;