import React, { useRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';

// Style Imports
import './Accordion.scss';

const Accordion = ({
    className,
    variant,
    id,
    buttonText,
    children,
    minAnimationDuration,
    maxAnimationDuration,
    speedDivider,
    easing,
}) => {
    const [isOpen, setIsOpen] = useState(false);
    const [height, setHeight] = useState(isOpen ? 'auto' : 0);
    const [speed, setSpeed] = useState(0);

    const content = useRef(null);

    // Class definitions
    const baseClass = 'accordion';
    const variantClass = variant ? `accordion--${variant}` : '';
    const customClass = className;
    const activeClass = isOpen ? 'is-open': '';
    const classes = [baseClass, variantClass, customClass, activeClass].filter(Boolean).join(' ');

    useEffect(() => {
        if (isOpen) {
            const contentHeight = content.current.scrollHeight;

            const time = contentHeight / speedDivider;

            const animation =
                time < minAnimationDuration
                ? minAnimationDuration
                : time > maxAnimationDuration
                ? maxAnimationDuration
                : time;

            content.current.style.visibility = 'visible';

            setHeight(contentHeight);
            setSpeed(animation);
        }
        else {
            setHeight(0);
            
            setTimeout(() => {
                content.current.style.visibility = 'hiddesn';
            }, speed * speedDivider);
        }
    }, [
        isOpen,
        speed,
        minAnimationDuration,
        maxAnimationDuration,
        speedDivider,
        children,
    ]);

    const toggleContent = (event) => {
        event.preventDefault();
        setIsOpen(!isOpen);
    };

    return (
        <div className={classes} id={id}>
            <h2 className='accordion__heading'>
                <button
                    className='accordion__button'
                    aria-controls={id + '-content'}
                    aria-expanded={isOpen}
                    onClick={toggleContent}
                >
                    {buttonText}
                </button>
            </h2>
            
            <section className='accordion__content-wrapper'
                style={{
                    transition: `height ${speed}s ${easing}`,
                    height: height,
                }}
                id={id + '-content'}
                aria-hidden={!isOpen}
            >
                <div className='accordion__content' ref={content} style={{ overflow: 'auto' }}>
                    {children}
                </div>
            </section>
        </div>
    );
};

Accordion.defaultProps = {
    id: PropTypes.string.isRequired,
    buttonText: PropTypes.string.isRequired,
    children: PropTypes.node.isRequired,
    minAnimationDuration: 0.3,
    maxAnimationDuration: 1,
    speedDivider: 1000,
    easing: 'ease-in-out',
};

Accordion.propTypes = {
    className: PropTypes.string,
    variant: PropTypes.string,
    minAnimationDuration: PropTypes.number,
    maxAnimationDuration: PropTypes.number,
    speedDivider: PropTypes.number,
    easing: PropTypes.string,
};

export default Accordion;